

export default {
	data() {
		return {
			slider: null,

		}
	},
	props: {
		options: {
			type: Object,
			default: () => {
			}
		},
		rangeClass: {
			type: String,
			default: ''
		},
		from: {
			type: String,
			default: ''
		}
	},
	mounted() {
		this.slider = ionRangeSlider.ionRangeSlider('.' + this.rangeClass, this.options);
		// ionRangeSlider.ionRangeSlider('.' + this.rangeClass, this.options);
	},

	destroyed() {
		this.slider?.destroy()
	}
}
