
import { mapGetters, mapMutations } from "vuex";
import filters from "@/mixins/filters";
import seoTags from "@/apollo/queries/seoTags";

export default {
	mixins: [filters],
	data() {
		return {
			ready: false,
			masks: {
				yearInput: {
					mask: "9{1,4}",
					showMaskOnHover: false,
					showMaskOnFocus: true,
					// oncomplete: () => this.$emit('phoneMaskComplete'),
					// onincomplete: () => this.$emit('onincomplete'),
					// definitions: {
					// 	"#": {
					// 		validator: "[1234569]",
					// 	},
					// },
					max: Number(new Date().getFullYear())
				}
			},
			inputFrom: this.$route.query.year_from? Number(this.$route.query.year_from) : '',
			inputTo: this.$route.query.year_to ? Number(this.$route.query.year_to) : '',
			min: 0,
			max: 0
		}
	},
	props: {
		from: String,
		to: String
	},

	computed: {
		...mapGetters({
			filters: 'filters/filters/filters',
			chosen: 'filters/filters/chosen'
		}),
		filterYear() {
			return this.filters.year
		},
		getPriceOptions() {
			return {
				type: 'double',
				grid: false,
				realTime: true,
				step: 1,
				from: this.inputFrom,
				to: this.inputTo,
				min: Number(this.filterYear?.[0]) - 1,
				max: Number(this.filterYear?.[1]),
				onChange: (event) => {
					this.inputFrom = event.from
					this.inputTo = event.to
					this.setChosen({ key: 'yearFrom', value: this.inputFrom })
					this.setChosen({ key: 'yearTo', value: this.inputTo })
				},
				// onFinish: (event) => this.sendPrice(event),

			}
		}
	},
	mounted() {
		let im = new Inputmask(this.masks.yearInput);
		im.mask(this.$refs.inputLeft__year);
		im.mask(this.$refs.inputRight__year);

		setTimeout(()=>{
			if (this.$route.query.year_from && this.$route.query.year_to) {
			this.inputFrom = Number(this.$route.query.year_from)
			this.inputTo = Number(this.$route.query.year_to)
			this.setChosen({ key: 'yearFrom', value: this.inputFrom })
			this.setChosen({ key: 'yearTo', value: this.inputTo })
		}
		else {
			this.inputFrom = this.filterYear?.[0] - 1
			this.inputTo = this.filterYear?.[1]
			this.setChosen({ key: 'yearFrom', value: this.inputFrom })
			this.setChosen({ key: 'yearTo', value: this.inputTo })
		}
			this.ready= true;
		},900)
	},
	methods: {
		...mapMutations({
			setCurrentCar: 'form/form-credit/SET_CURRENT_CAR',
			setChosen: 'filters/filters/SET_CHOSEN',
			setIsFilterClick: 'filters/filters/SET_IS_FILTER_CLICK'
		}),
		...mapMutations({
			setTabComponent: 'modal/modal-choose/SET_TAB_COMPONENT'
		}),
		// inp(value, tag) {
		// 	tag === 'priceFrom' && this.$refs["range-year"].slider.update({ from: Number(String(value).replace('₽', '').split(' ').join('')) })
		// 	tag === 'priceTo' && this.$refs["range-year"].slider.update({ to: Number(String(value).replace('₽', '').split(' ').join('')) })
		
		// },
		inp(value, tag) {
      if(tag === 'yearFrom'){
        this.inputFrom = value
        this.$refs["range-year"].slider.update({ 
          from: value
        })
      }
      if(tag === 'yearTo'){
        this.inputTo = value
         this.$refs["range-year"].slider.update({ 
          to: value
         })
      }
		let inputFrom = this.inputFrom
		let inputTo = this.inputTo
		this.setChosen({ key: 'yearFrom', value: inputFrom })
		this.setChosen({ key: 'yearTo', value: inputTo })
    	},

	},

}
